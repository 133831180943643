import MediaCard from './Gallery/Card';

import anti from './ProductImages/Antioxidant_mnd.jpg'
import awIc from './ProductImages/AWAKE_ICON.jpg'
import cwc from './ProductImages/colouredwhippedcream.jpg'
import egi from './ProductImages/ENERGY_ICON.jpg'
import mi from './ProductImages/image0.jpg'
import india from './ProductImages/india-24k-icon-products-2019-02.jpg'

const products = [
  {
    img: anti,
    title: 'I.C.O.N. Antioxidant',
    description: '',
    price: ""
  },
  {
    img: awIc,
    title: 'I.C.O.N. Awake',
    description: '',
    price: ""
  },
  {
    img: cwc,
    title: 'milk_shake',
    description: 'Coloured Whipped Cream',
    price: ""
  },
  {
    img: egi,
    title: 'Energy I.C.O.N.',
    description: '',
    price: ""
  },
  {
    img: mi,
    title: 'milk_shake Integrity',
    description: '',
    price: ""
  },
  {
    img: india,
    title: 'I.C.O.N. India 24k',
    description: '',
    price: ""
  },
  
];


export default function Products() {

  function generateCards() {
    const cards = [];
    for (const item of products) {
      cards.push(<MediaCard image={item.img}
        title={item.title}
        description={item.description}
        price={item.price}
        key={item.title}
      />)
    }
    return cards;
  }

  return (
    <div style={{
      display: "flex", width: "100%", flexDirection: "column",
      alignItems: "center"
    }}>
      <h2>Produkter</h2>
      <div style={{
        display: "flex",
        flexDirection: "Row",
        justifyContent: "center",
        alignItems: "flex-start",
        flexWrap: "wrap",
        width: "100%"
      }}>
        {generateCards()}
      </div>
    </div>
  )
}
