import React from 'react'
import hairLenPic from './hairlength.JPG'

export default function HairLength() {
    return (
        <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1}}>
            <h2>Hårlängder</h2>
            <div style={{height: "15px"}}/>
            <img src={hairLenPic} alt="hårlängd" height={250} width={150}/>
        </div>
    )
}
