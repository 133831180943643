import React from 'react'
import Products from './Products'

export default function ProductsAndPrices() {
    return (
        <div style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            flexDirection: "row"
        }}
        >
            <Products />
        </div>
    )
}
