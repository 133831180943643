import { IconButton, Paper } from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import { Email, Instagram } from "@material-ui/icons";

export default function Head() {
    return (
        <Paper
            style={{
                width: '100%',
                color: 'white',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "space-around",
                backgroundColor: 'black',
                height: 150,
            }}>

                    <h1 >
                    Salong Herkules
                    </h1>

                    <div style={{ width: "100%",display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <PhoneIcon />
                            <h5>&nbsp;018-26 04 05</h5>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Email />
                            <h5>&nbsp;salongherkules@hotmail.com</h5>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <IconButton href="https://www.instagram.com/salongherkules/" target="_blank" rel="noopener noreferrer">
                                <Instagram htmlColor="white"/>
                            </IconButton>
                        </div>

                    </div>
        </Paper >
    )
}
